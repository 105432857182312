/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/signup.module.css"
import Utils from "../../../utils"
import Keys from '../../../constants/keys'
// import { navigate } from "gatsby"
import { navigate } from "gatsby"

class Signup extends React.Component {
  constructor() {
    super();
    this.state = {ready: false, plan: ""}
  }
  componentDidMount() {
    let invite_code = Utils.getQueryVariable(this.props.location, "i")
    let referral_code = Utils.getQueryVariable(this.props.location, "r")
    navigate(`/buidl/?i=${invite_code}&r=${referral_code}`)
  }
  //   let invite_code = Utils.getQueryVariable(this.props.location, "i")
  //   let interval = setInterval(() => {
  //     if(!window.grecaptcha) {
  //       return
  //     }
  //     clearInterval(interval);
  //     window.grecaptcha.ready(() => {
  //       window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'checkinvite'}).then((recaptchaToken) => {
  //         return fetch(`/api/check_invite`, {
  //           method: "POST",
  //           body: JSON.stringify({recaptcha_token: recaptchaToken, invite_code: invite_code}),
  //           headers: {
  //             "Content-Type": "application/json",
  //             "X-CSRFToken": Utils.getCookie('csrftoken'),
  //           }
  //         })
  //       }).then((r) => {
  //         if(r.ok) {
  //           return r.json()
  //         } else {
  //           return
  //         }
  //       }).then((response) => {
  //         if(!response || !response.ok) {
  //           navigate("/invite-required/", {replace: true})
  //           return
  //         }
  //         this.setState({ready: true})
  //       })
  //     });
  //   }, 10)
  // }
  render () { return <div></div> }
  //   const handleChange = (e) => {
  //     let state = {};
  //     state[e.target.name] = e.target.value;
  //     this.setState(state)
  //   }
  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     let invite_code = Utils.getQueryVariable(this.props.location, "i")
  //     let referral_code = Utils.getQueryVariable(this.props.location, "r")
  //     navigate(`/${this.state.plan}/?i=${invite_code}&r=${referral_code}`)
  //   }
  //   if(this.state.ready) {
  //     return (
  //       <section className={styles.signup}>
  //       <span className={styles.modalHeader}><Title title="rivet" subtitle="signup" /></span>
  //       <div className={styles.center}>
  //       <form className={styles.form} onSubmit={handleSubmit}>
  //       <div>
  //       <input
  //       type="radio"
  //       name="plan"
  //       id="buidl"
  //       checked={this.state.plan == "buidl"}
  //       onChange={handleChange}
  //       className={styles.formControl}
  //       value="buidl"
  //       /><label className={styles.plans} htmlFor="buidl">
  //       <ul><h4>The BUIDL Plan</h4>
  //       <li>Best for projects looking for product-market fit or developers new to Ethereum.</li>
  //       <li>No payment information required—upgrade to SCAEL when you’re ready.</li>
  //       <li>Limited to 3,000,000 RPC requests per month.</li>
  //       </ul></label>
  //       <input
  //       type="radio"
  //       name="plan"
  //       id="scael"
  //       checked={this.state.plan == "scael"}
  //       onChange={handleChange}
  //       value="scael"
  //       className={styles.formControl}
  //       /><label className={styles.plans} htmlFor="scael">
  //       <ul><h4>The SCAEL Plan</h4>
  //       <li>Best for projects with established products or projects expecting rapid growth.</li>
  //       <li>Valid payment method required at sign up. (Payments processed by Stripe.)</li>
  //       <li>Includes 3,000,000 free RPC requests per month—just like the BUIDL plan.</li>
  //       </ul></label>
  //       </div>
  //       <div className={styles.arrowButton}>
  //       <input
  //       type="submit"
  //       value="⇢"
  //       className={styles.submit}
  //       />
  //       </div>
  //       </form>
  //       </div>
  //       </section>
  //     )
  //   } else {
  //       return (<section className={styles.signup}>
  //         Good call!! Let's do this!
  //       </section>)
  //   }
  // }
}

export default Signup
